import request from 'graphql-request';
import { gameInsightQuery } from './Graphql/Queries/gameInsightsQuery';
import { getToken } from './helpers';
import logger from './logger';

export async function fetchGameInsightData (variables,url) {
    try{
    return getToken().then((token)=> {
        let headers = {
            "authorization": token
         }
         return request(url, gameInsightQuery, variables, headers).then((res) => {
            return res
        })
     })
   }
     catch(err){
        logger(`Error in fetching game insight data ${err}`, "error")
     }
  }
