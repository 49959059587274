import { init, browserTracingIntegration, replayIntegration } from '@sentry/react';
import logger from './logger';
function validateUrl(url) {
    var p = /^(http|https):\/\/[^ "]+$/;
    return p.test(url);
}
export var Instrumentation;
(function (Instrumentation) {
    function getWebConfig() {
        var _a, _b, _c;
        var config = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.app_data) === null || _a === void 0 ? void 0 : _a.appcmsMain) === null || _b === void 0 ? void 0 : _b.analytics) === null || _c === void 0 ? void 0 : _c.sentryConfig;
        if (!config)
            return null;
        return config.find(function (item) {
            if (item.platforms && item.platforms.length) {
                return item.platforms.includes('web');
            }
        });
    }
    function initSentry() {
        var config = getWebConfig();
        if (!config) {
            logger('Sentry is not enabled for web');
        }
        if (!(config === null || config === void 0 ? void 0 : config.dns) || !validateUrl(config.dns)) {
            logger('Sentry DSN is not valid');
            return;
        }
        logger('Sentry is enabled for web');
        init({
            dsn: config.dns,
            integrations: [browserTracingIntegration(), replayIntegration()],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }
    Instrumentation.initSentry = initSentry;
})(Instrumentation || (Instrumentation = {}));
