import axios from 'axios'
import {config} from '../server/config';
import { minifiedScheduleQuerySHA,minifiedScheduleQuery } from './Graphql/Queries/scheduleGameQuery';
import logger from './logger';

export function fetchScheduleGameData(variables,url) {
    try{
       return axios.get(url+'?extensions={"persistedQuery":{"version":1,"sha256Hash":'+JSON.stringify(minifiedScheduleQuerySHA)+'}}&variables='+JSON.stringify(variables)).then((response) => {
         
         //if persisted query not found 
         let res = response.data
         if(res.errors) {
            if(res && res.errors && res.errors[0] && res.errors[0].message && res.errors[0].message === "PersistedQueryNotFound") {
               return cacheScheduleGameData(variables,url) 
           }
          }
          else{
            let data = res?.data
            return data
          }
        })
     }
     catch(err){
      logger(`Schedule fetch error: ${err}`, "error")
     }
  }

  export function cacheScheduleGameData (variable,url) {
    let endpoint = `${config.cachedAPI || config.apiBaseUrl}/graphql`
      let finalPayload = {
        "query": minifiedScheduleQuery,
        "variables": variable,
        "extensions": {
            "persistedQuery": {
                "version": 1,
                "sha256Hash": minifiedScheduleQuerySHA
            }
        }
      }
      try{
        return axios.post(endpoint, finalPayload)
            .then(response => {  
               return fetchScheduleGameData(variable,url)
            })
            .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    logger(error.response.data, "error");
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    logger(error.message, "error");
                  }
            });
     }
     catch(err){
        logger(`Cache schedule fetch error: ${err}`, "error")
     }
  }