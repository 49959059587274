import { generateHash, generateMinifiedQuery } from "../hash";
export const searchQuery = `query Search(
  $site: String!
  $keyword: String!
  $languageCode: String
  $countryCode: String
  $userState: String
  $limit: Int
  $offset: Int
  $relatedSearch: Boolean
  $types: [String!]
) {
  search(
    site: $site
    keyword: $keyword
    languageCode: $languageCode
    countryCode: $countryCode
    userState: $userState
    limit: $limit
    offset: $offset
    relatedSearch: $relatedSearch
    types: $types
  ) {
    records {
      id
      title
      ... on Video {
        runtime
        monetizationBadgeIdentifier
        plans {
          type
          planIds
        }
        badges{
          id
          title
          objectKey
          contentType
          alignment
          style{
            backgroundColor
            gradientColor
            textColor
            textStyle
            borderColor
            borderWidth
          }
          visible
        }
      }
      gist {
        id
        title
        permalink
        contentType
        description
        imageGist {
          r32x9
          r16x9
          r4x3
          r3x4
          r1x1
          r9x16
        }
      }
    }
    related {
      id
      title
      ... on Video {
        runtime
      }
      gist {
        id
        title
      }
    }
  }
}`;

export const minifiedSearchQuery =generateMinifiedQuery(searchQuery);
export const searchQuerySHA = generateHash(minifiedSearchQuery);