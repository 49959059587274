import axios from 'axios';
import {config} from '../server/config';
import { searchQuerySHA,minifiedSearchQuery } from './Graphql/Queries/searchQuery';
import logger from './logger';


export async function fetchSearchData(variables, url) {
    try {
        return axios.get(url+'?extensions={"persistedQuery":{"version":1,"sha256Hash":'+JSON.stringify(searchQuerySHA)+'}}&variables='+JSON.stringify(variables)).then((response) => {
            //if persisted query not found 
         let res = response.data
         if(res.errors) {
            if(res && res.errors && res.errors[0] && res.errors[0].message && res.errors[0].message === "PersistedQueryNotFound") {
               return cacheSearchData(variables,url) 
           }
          }
          else{
            let data = res?.data
            return data
          }
  
        });
    }
    catch (err) {
        logger(`Search fetch error: ${err}`, "error");
    }
}

export function cacheSearchData (variable,url) {
    let endpoint = `${config.cachedAPI || config.apiBaseUrl}/graphql`
      let finalPayload = {
        "query": minifiedSearchQuery,
        "variables": variable,
        "extensions": {
            "persistedQuery": {
                "version": 1,
                "sha256Hash": searchQuerySHA
            }
        }
      }
      try{
        return axios.post(endpoint, finalPayload)
            .then(response => {  
               return fetchSearchData(variable,url)
            })
            .catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    logger(error.response.data, "error");
                  } else {
                    // Something happened in setting up the request that triggered an Error
                    logger(error.message, "error");
                  }
            });
     }
     catch(err){
        logger(`Cache search fetch error: ${err}`, "error")
     }
  }