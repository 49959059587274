import logger from "../../logger";

export function parseJSON(inputString, fallback = null) {
    try {
      if (inputString && inputString !== "undefined") {
        return JSON.parse(inputString);
      }else {
        return null
      }
    } catch (e) {
      logger(`Error parsing JSON: ${e}`, "error");
      return fallback;
    }
  };